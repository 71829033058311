import * as React from "react";
import { Heading, Paragraph } from "grommet";
import { ContentBlock } from "../../../components/content-block";

import DataStrategyImage from "../../../images/pages/consulting/data-strategy.png";
import DataComplianceImage from "../../../images/pages/consulting/data-compliance.png";
import DataGovernanceImage from "../../../images/pages/consulting/data-governance.png";
import OutsourcingImage from "../../../images/pages/consulting/outsourcing.png";

const ConsultingPage = () => {
  return (
    <>
      <Heading level={2} style={{ color: "#7D4CDB" }}>Consulting</Heading>
      <Heading level={3} style={{ color: "#7D4CDB" }}>Data Strategy</Heading>
      <ContentBlock image={DataStrategyImage}>
        <Paragraph fill>
          Prosseguindo a missão de apoiar os nossos clientes e parceiros na sua
          jornada "orientada para os dados" desenvolvemos uma metodologia que
          permite a democratização do uso de dados em toda a organização com
          governação e a nível estratégico. É um fator de sucesso crítico neste
          processo de transformação a capacidade de definir uma visão futura
          correta em linha com os verdadeiros desafios atuais e esperados do
          negócio. Para ter esta visão é necessário avaliar a utilização dos
          dados pela organização nas dimensões de: Estratégia, Arquitetura,
          Informação, Organização, Dados, Processos e Pessoas, com isto
          garantimos um plano de ação eficaz e resultados percetíveis. A nossa
          metodologia permite que o âmbito e a profundidade do trabalho sejam
          realizados para ter em conta a necessidade e ambição do cliente,
          permitindo assim que a transformação ocorra em áreas específicas e/ou
          em assuntos ou em toda a organização.
        </Paragraph>
      </ContentBlock>

      <Heading level={3} style={{ color: "#7D4CDB" }}>Data Governance</Heading>
      <ContentBlock image={DataGovernanceImage} reverse>
        <Paragraph fill>
          Com a transformação digital e a crescente competitividade do mercado,
          tornou-se essencial evoluir as práticas de governação e gestão de
          dados. Estabelecer um elevado nível de confiança nesta gestão é um
          pré-requisito para alcançar o objetivo de se tornar “Data-Driven“.
        </Paragraph>
      </ContentBlock>
      <Paragraph fill>
        Governança de Dados consiste numa orquestração formal de pessoas,
        processos e tecnologia, pelo que a sua implementação vai além das
        questões técnicas ou tecnológicas, é também necessário mudar em aspetos
        não tecnológicos, ter um forte envolvimento e um alinhamento próximo com
        a comunidade de utilizadores finais e a capacidade de definir uma visão
        correta do futuro em linha com os verdadeiros desafios do negócio atuais
        e futuros são o sucesso crítico e os fatores fundamentais para assegurar
        os planos de ação e resultados notáveis.
      </Paragraph>
      <Paragraph fill>
        Através de uma metodologia alinhada com as melhores práticas de mercado,
        ajudamos a sua empresa a estabelecer uma estratégia de governação
        personalizada e sólida, e um processo de qualidade recorrente,
        aumentando assim a agilidade e eficiência dos seus dados.
      </Paragraph>

      <Heading level={3} style={{ color: "#7D4CDB" }}>Data Compliance</Heading>
      <ContentBlock image={DataComplianceImage}>
        <Paragraph fill>
          Com a transformação digital e a crescente competitividade do mercado,
          tornou-se essencial evoluir as práticas de governação e gestão de
          dados. Estabelecer um elevado nível de confiança nesta gestão é um
          pré-requisito para alcançar o objetivo de se tornar "data-driven“.{" "}
        </Paragraph>
      </ContentBlock>
      <Paragraph fill>
        Através de uma metodologia alinhada com as melhores práticas de mercado,
        ajudamos a sua empresa a estabelecer uma estratégia de governação
        personalizada e sólida, e um processo de qualidade recorrente,
        aumentando assim a agilidade e eficiência dos seus dados.
      </Paragraph>
      <Paragraph fill>
        Recomendamos avaliação para quem pretenda iniciar um programa de
        governação e gestão de dados ou avaliar o programa atual de forma a
        aumentar a sua maturidade. Através da avaliação podemos obter uma
        fotografia da situação atual, e identificar a visão de onde se quer ir,
        de modo que a partir daí se estabeleça um plano estratégico para que as
        coisas aconteçam de forma realista, num contexto específico, com o
        melhor tempo e custo-benefício.
      </Paragraph>

      <Heading level={3} style={{ color: "#7D4CDB" }}>Outsourcing</Heading>
      <ContentBlock image={OutsourcingImage} reverse>
        <Paragraph fill>
          Oferecemos complementação de equipa aos nossos clientes, com
          profissionais treinados para atender às suas exigências específicas.
          Realizamos avaliações estruturadas de desempenho e monitorizando
          continuamente o desempenho dos profissionais, garantindo assim que
          realiza e entrega os resultados esperados.
        </Paragraph>
      </ContentBlock>
    </>
  );
};

export default ConsultingPage;
